import { useEffect, useState } from "react";

import DstButton from "../../../../../components/DstButton/DstButton";
import MultiSelectDropdown from "../../../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Popup from "../../../../../components/Popup/Popup";
import translate from "../../../../../services/Translate";
import { fetchUsers } from "../CohortUsers.function";
import { CohortUsersFlagsPopupProperties } from "./CohortUsersFlagsPopup.d";
import { addUsersFlags, fetchFlags } from "./CohortUsersFlagsPopup.function";

export const CohortUsersFlagsPopup = ({
  language,
  cohortId,
  openPopup,
  setOpenPopup,
  userIds,
  setUsersSelected,
  setLoading,
  setCohortUsers,
}: CohortUsersFlagsPopupProperties) => {
  const [flagsToAdd, setFlagsToAdd] = useState<number[]>([]);
  const [flags, setFlags] = useState<any[]>([]);
  const [isFlagsLoading, setIsFlagsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (openPopup) {
      fetchFlags(language, setFlags, setIsFlagsLoading);
    }
  }, [openPopup, language]);

  return (
    <Popup
      show={openPopup}
      onClose={() => setOpenPopup(false)}
      modalTitle={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_FLAGS_TO_USERS.TITLE")}
    >
      <div className="d-flex flex-column">
        <div className="flex-grow-1">
          <MultiSelectDropdown
            label={
              flagsToAdd.length > 0
                ? `${translate(language, "PAGES.COHORT.USERS.MODALS.ADD_FLAGS_TO_USERS.FLAGS_SELECTED")} ${
                    flagsToAdd.length
                  }`
                : translate(language, "PAGES.COHORT.USERS.MODALS.ADD_FLAGS_TO_USERS.SELECT_FLAGS")
            }
            language={language}
            items={flags}
            mock={isFlagsLoading}
            toggleClass="border"
            badgeItemProperty="name"
            displayItem={(item) => item.name}
            onConfirm={(selectedItems) => {
              setFlagsToAdd(selectedItems.map((item) => item.id));
            }}
          />
        </div>
        <div className="d-flex justify-content-end mt-4">
          <DstButton
            btnWidth="100px"
            variant="primary"
            value={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_FLAGS_TO_USERS.CONFIRM_BUTTON")}
            clickFunction={() => {
              addUsersFlags(language, cohortId, userIds, flagsToAdd, setIsAdding, () => {
                setLoading(true);
                fetchUsers(cohortId, setCohortUsers, language, () => {
                  setLoading(false);
                  setUsersSelected([]);
                  setOpenPopup(false);
                });
              });
            }}
            btnClass="me-2"
            disabled={isAdding || flagsToAdd.length === 0}
            loading={isAdding}
          />
        </div>
      </div>
    </Popup>
  );
};

export default CohortUsersFlagsPopup;
