import React, { useEffect, useState } from "react";
import { Dropdown, Form, Modal, Placeholder } from "react-bootstrap";

import DstButton from "../../../../components/DstButton/DstButton";
import MultiSelectDropdown from "../../../../components/MultiSelectDropdown/MultiSelectDropdown";
import MultiSelectLiveDropdown from "../../../../components/MultiSelectLiveDropdown/MultiSelectLiveDropdown";
import Popup from "../../../../components/Popup/Popup";
import { useSession } from "../../../../contexts/SessionContext";
import { calculateEndDate, daysMapping } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { fetchMeetingMetaTypes } from "../../Meetings.function";
import { Animator, LanguageKeys, MeetingCreationModalProps, SelectedDay, VisibilityKeys } from "./CreationModal.d";
import { createMeeting, getProjects, getResources, languageMapping, visibilityOptions } from "./CreationModal.function";

const MeetingCreationModal = ({ closeModal, language, show }: MeetingCreationModalProps) => {
  const { email } = useSession();
  const [afternoonEnd, setAfternoonEnd] = useState<string>("");
  const [afternoonStart, setAfternoonStart] = useState<string>("");
  const [animators, setAnimators] = useState<Animator[]>([]);
  const [description, setDescription] = useState<string>("");
  const [duration, setDuration] = useState(120);
  const [durationError, setDurationError] = useState(false);
  const [endDate, setEndDate] = useState<string>("");
  const [generateZoomLink, setGenerateZoomLink] = useState<boolean>(true);
  const [meetingLink, setMeetingLink] = useState<string>("");
  const [meetingType, setMeetingType] = useState<string>("masterclass");
  const [meetingTypes, setMeetingTypes] = useState<{ value: string; display: string }[]>([]);
  const [morningEnd, setMorningEnd] = useState<string>("");
  const [morningStart, setMorningStart] = useState<string>("");
  const [programs, setPrograms] = useState<string[]>([]);
  const [projects, setProjects] = useState<{ value: number; display: string }[]>([]);
  const [selectedAnimators, setSelectedAnimators] = useState<Animator[]>([]);
  const [selectedDays, setSelectedDays] = useState<SelectedDay[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageKeys>("FR");
  const [selectedOrganizer, setSelectedOrganizer] = useState<string>(email!);
  const [selectedProgram, setSelectedProgram] = useState<string | null>(null);
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [selectedVisibility, setSelectedVisibility] = useState<VisibilityKeys>("private");
  const [startDate, setStartDate] = useState<string>("");
  const [targetCohortIds, setTargetCohortIds] = useState<number[]>([]);
  const [title, setTitle] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getResources(setAnimators, setPrograms, language);
    fetchMeetingMetaTypes(setMeetingTypes, setLoading, language);
    getProjects(setProjects, language);
  }, [language]);

  useEffect(() => setSelectedOrganizer(email!), [email]);

  const getDaysOptions = (language: string) =>
    Object.entries(daysMapping(language))
      .filter(([_, value]) => value !== undefined)
      .map(([key, day]) => ({
        id: key,
        day: day!,
      }));

  const handleConfirm = () => {
    const optionalFields = {
      afternoon_end_date: afternoonEnd || "",
      afternoon_start_date: afternoonStart || "",
      animators: selectedAnimators.length > 0 ? selectedAnimators.map((ani) => ani.username) : undefined,
      meeting_description: description || "",
      meeting_link: meetingLink || "",
      morning_end_date: morningEnd || "",
      morning_start_date: morningStart || "",
    };

    const payload = {
      cohort_ids: targetCohortIds,
      days: meetingType === "multiple days" && selectedDays?.length > 0 ? selectedDays.map((day) => day.id) : undefined,
      end: meetingType === "multiple days" ? endDate : calculateEndDate(startDate, duration),
      generate_zoom_link: generateZoomLink,
      masterclass_language: meetingType === "masterclass" ? selectedLanguage : "",
      masterclass_program: meetingType === "masterclass" ? selectedProgram : "",
      meeting_name: title,
      meeting_type: meetingType,
      organizer_email: selectedOrganizer || "",
      project_id: ["call_projet"].includes(meetingType) && selectedProject ? selectedProject : undefined,
      start: startDate,
      visibility: selectedVisibility,
      ...optionalFields,
    };

    createMeeting(payload, language, setLoading, () => {
      resetSelection();
      closeModal();
    });
  };

  const resetSelection = () => {
    setAfternoonEnd("");
    setAfternoonStart("");
    setDescription("");
    setDuration(120);
    setDurationError(false);
    setEndDate("");
    setGenerateZoomLink(true);
    setMeetingLink("");
    setMeetingType("masterclass");
    setMorningEnd("");
    setMorningStart("");
    setSelectedAnimators([]);
    setSelectedDays([]);
    setSelectedLanguage("FR");
    setSelectedProgram(null);
    setSelectedProject(null);
    setSelectedVisibility("private");
    setStartDate("");
    setTargetCohortIds([]);
    setTitle("");
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);

    if (parsedValue > 0) {
      setDurationError(false);
      setDuration(parsedValue);
    } else {
      setDurationError(true);
      setDuration(0);
    }
  };

  const isFormValid = (() => {
    if (!title || !startDate || !selectedOrganizer) {
      return false;
    }

    switch (meetingType) {
      case "masterclass":
        return !!selectedLanguage && !!selectedProgram;

      case "call_projet":
        return !!selectedProject;

      case "multiple days":
        return !!endDate && selectedDays.length > 0;

      default:
        return !!duration;
    }
  })();

  return (
    <Popup show={show} onClose={closeModal} modalTitle={translate(language, "PAGES.MEETINGS.MODAL.TITLE")} scrollable>
      <Form id="modal-add-meeting">
        <Form.Group>
          <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.MEETING_TYPE")}</Form.Label>
          {meetingTypes.length > 0 ? (
            <Dropdown>
              <Dropdown.Toggle
                className="w-100 d-flex justify-content-between align-items-center border-secondary"
                variant=""
              >
                {meetingType}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {meetingTypes
                  .filter((type) => type.value !== "partner")
                  .map((type) => (
                    <Dropdown.Item key={type.value} onClick={() => setMeetingType(type.value)}>
                      {type.display}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Placeholder animation="glow">
              <Placeholder.Button className="w-100" />
            </Placeholder>
          )}
        </Form.Group>
        {meetingType === "masterclass" && (
          <React.Fragment>
            <Form.Group className="mt-3">
              <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.PROGRAM")}</Form.Label>
              {programs.length > 0 ? (
                <MultiSelectDropdown
                  btnWidth="100%"
                  label={selectedProgram || translate(language, "PAGES.MEETINGS.MODAL.LABELS.PROGRAM")}
                  items={programs.map((program, index) => ({ id: index, name: program }))}
                  onConfirm={(selected) => {
                    setSelectedProgram(selected.length > 0 && selected[0].name);
                  }}
                  badgeItemProperty="name"
                  displayItem={(item) => item.name}
                  language={language}
                  toggleClass={`text-truncate border-secondary w-100 ${!selectedProgram ? "text-muted" : ""}`}
                  isSingleSelect
                />
              ) : (
                <Placeholder animation="glow">
                  <Placeholder.Button className="w-100" />
                </Placeholder>
              )}
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.LANGUAGE")}</Form.Label>
              <Dropdown>
                <Dropdown.Toggle
                  className="w-100 d-flex justify-content-between align-items-center border-secondary"
                  variant=""
                >
                  {languageMapping(language)[selectedLanguage]}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {Object.keys(languageMapping(language)).map((key) => (
                    <Dropdown.Item key={key} onClick={() => setSelectedLanguage(key as LanguageKeys)}>
                      {languageMapping(language)[key as LanguageKeys]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </React.Fragment>
        )}

        {["call_projet"].includes(meetingType) && (
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.PROJECT")}</Form.Label>
            {projects.length > 0 ? (
              <MultiSelectDropdown
                btnWidth="100%"
                label={
                  selectedProject
                    ? projects.find((project) => project.value === selectedProject)?.display || ""
                    : translate(language, "PAGES.MEETINGS.MODAL.PLACEHOLDERS.SELECT_PROJECT")
                }
                items={projects.map((project) => ({ ...project, id: project.value }))}
                onConfirm={(selected) => {
                  setSelectedProject(selected[0]?.value || null);
                }}
                badgeItemProperty="display"
                displayItem={(item) => item.display}
                language={language}
                toggleClass={`text-truncate border-secondary w-100 ${!selectedProject ? "text-muted" : ""}`}
                isSingleSelect
              />
            ) : (
              <Placeholder animation="glow">
                <Placeholder.Button className="w-100" />
              </Placeholder>
            )}
          </Form.Group>
        )}
        <Form.Group className="mt-3">
          <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.TITLE")}</Form.Label>
          <Form.Control
            className={`border-secondary ${!title ? "text-muted" : ""}`}
            type="text"
            placeholder={translate(language, "PAGES.MEETINGS.MODAL.PLACEHOLDERS.TITLE_SELECT")}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>

        {["masterclass", "multiple days"].includes(meetingType) && (
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.COHORTS")}</Form.Label>
            <MultiSelectLiveDropdown
              category={["cohorts"]}
              label={
                targetCohortIds.length > 0
                  ? `${translate(language, "PAGES.MEETINGS.MODAL.PLACEHOLDERS.COHORT_SELECTED")} ${targetCohortIds.join(
                      ", "
                    )}`
                  : translate(language, "PAGES.MEETINGS.MODAL.PLACEHOLDERS.SELECT_COHORT")
              }
              language={language}
              onConfirm={(selectedCohorts) => {
                setTargetCohortIds(selectedCohorts.map((cohort) => parseInt(cohort.value.id, 10)));
              }}
              toggleClass={`text-truncate w-100 border-secondary ${targetCohortIds.length === 0 ? "text-muted" : ""}`}
            />
          </Form.Group>
        )}

        <Form.Group className="mt-3">
          <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.START_DATE")}</Form.Label>
          <Form.Control
            className={`border-secondary ${!startDate ? "text-muted" : ""}`}
            type="datetime-local"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Form.Group>

        {meetingType === "multiple days" ? (
          <React.Fragment>
            <Form.Group className="mt-3">
              <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.END_DATE")}</Form.Label>
              <Form.Control
                className={`border-secondary ${!endDate ? "text-muted" : ""}`}
                type="datetime-local"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.MORNING_START")}</Form.Label>
              <Form.Control
                className={`border-secondary ${!morningStart ? "text-muted" : ""}`}
                type="time"
                value={morningStart}
                onChange={(e) => setMorningStart(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.MORNING_END")}</Form.Label>
              <Form.Control
                className={`border-secondary ${!morningEnd ? "text-muted" : ""}`}
                type="time"
                value={morningEnd}
                onChange={(e) => setMorningEnd(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.AFTERNOON_START")}</Form.Label>
              <Form.Control
                className={`border-secondary ${!afternoonStart ? "text-muted" : ""}`}
                type="time"
                value={afternoonStart}
                onChange={(e) => setAfternoonStart(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.AFTERNOON_END")}</Form.Label>
              <Form.Control
                className={`border-secondary ${!afternoonEnd ? "text-muted" : ""}`}
                type="time"
                value={afternoonEnd}
                onChange={(e) => setAfternoonEnd(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.DAYS")}</Form.Label>
              <MultiSelectDropdown
                label={
                  selectedDays.length > 0
                    ? selectedDays.map((item) => item.day).join(", ")
                    : translate(language, "PAGES.MEETINGS.MODAL.PLACEHOLDERS.SELECT_DAYS")
                }
                items={getDaysOptions(language)}
                onConfirm={setSelectedDays}
                badgeItemProperty="day"
                displayItem={(item) => item.day}
                language={language}
                toggleClass={`text-truncate w-100 border-secondary ${selectedDays.length === 0 ? "text-muted" : ""}`}
              />
            </Form.Group>
          </React.Fragment>
        ) : (
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.DURATION")}</Form.Label>
            <Form.Control
              className={`border-secondary ${durationError ? "border-danger" : ""}`}
              type="text"
              value={duration}
              onChange={handleDurationChange}
            />
            {durationError && (
              <Form.Text className="text-danger">
                {translate(language, "PAGES.MEETINGS.MODAL.ERRORS.UNFILLED_DURATION")}
              </Form.Text>
            )}
          </Form.Group>
        )}

        <Form.Group className="mt-3">
          <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.VISIBILITY")}</Form.Label>
          <Dropdown>
            <Dropdown.Toggle
              className="w-100 d-flex justify-content-between align-items-center border-secondary"
              variant=""
            >
              {visibilityOptions(language)[selectedVisibility]}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              <Dropdown.Item onClick={() => setSelectedVisibility("datascientest")}>
                {translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.VISIBILITY.DATASCIENTEST")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSelectedVisibility("private")}>
                {translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.VISIBILITY.PRIVATE")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.ORGANIZER")}</Form.Label>
          <Form.Control className="border-secondary" type="text" readOnly value={selectedOrganizer} disabled />
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.ANIMATORS")}</Form.Label>
          <MultiSelectDropdown
            mock={animators.length === 0}
            btnWidth="100%"
            label={
              selectedAnimators.length > 0
                ? `${selectedAnimators.length} ${translate(
                    language,
                    `PAGES.MEETINGS.MODAL.PLACEHOLDERS.${
                      selectedAnimators.length === 1 ? "ANIMATOR_SELECTED" : "ANIMATORS_SELECTED"
                    }`
                  )}`
                : translate(language, "PAGES.MEETINGS.MODAL.PLACEHOLDERS.ANIMATORS")
            }
            items={animators}
            onConfirm={setSelectedAnimators}
            badgeItemProperty="username"
            displayItem={(item) => item.username}
            language={language}
            toggleClass={`text-truncate w-100 border-secondary ${selectedAnimators.length === 0 ? "text-muted" : ""}`}
          />
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.GENERATE_ZOOM_LINK")}</Form.Label>
          <Dropdown>
            <Dropdown.Toggle
              className="w-100 d-flex justify-content-between align-items-center border-secondary"
              variant=""
            >
              {generateZoomLink
                ? translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.ZOOM_LINK.YES")
                : translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.ZOOM_LINK.NO")}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              <Dropdown.Item onClick={() => setGenerateZoomLink(true)}>
                {translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.ZOOM_LINK.YES")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setGenerateZoomLink(false)}>
                {translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.ZOOM_LINK.NO")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>

        {!generateZoomLink && (
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.MEETING_LINK")}</Form.Label>
            <Form.Control
              className={`border-secondary ${!meetingLink ? "text-muted" : ""}`}
              type="text"
              placeholder={translate(language, "PAGES.MEETINGS.MODAL.PLACEHOLDERS.MEETING_LINK")}
              value={meetingLink}
              onChange={(e) => setMeetingLink(e.target.value)}
            />
          </Form.Group>
        )}

        <Form.Group className="mt-3">
          <Form.Label>{translate(language, "PAGES.MEETINGS.MODAL.LABELS.DESCRIPTION")}</Form.Label>
          <Form.Control
            className={`border-secondary ${!description ? "text-muted" : ""}`}
            as="textarea"
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
      </Form>

      <Modal.Footer>
        <DstButton
          variant="secondary"
          value={translate(language, "PAGES.MEETINGS.MODAL.BUTTONS.CANCEL")}
          clickFunction={() => {
            closeModal();
            resetSelection();
          }}
        />
        <DstButton
          value={translate(language, "PAGES.MEETINGS.MODAL.BUTTONS.CONFIRM")}
          clickFunction={handleConfirm}
          disabled={!isFormValid}
          loading={loading}
        />
      </Modal.Footer>
    </Popup>
  );
};

export default MeetingCreationModal;
