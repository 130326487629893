import { useEffect, useState } from "react";
import { FormCheck, FormControl } from "react-bootstrap";

import DstButton from "../../../../../components/DstButton/DstButton";
import Popup from "../../../../../components/Popup/Popup";
import translate from "../../../../../services/Translate";
import { CohortUsersProgressReportPopupProps, ReportParams } from "./CohortUsersProgressReportPopup.d";
import { downloadProgressReport } from "./CohortUsersProgressReportPopup.function";

export const CohortUsersProgressReportPopup = ({
  cohortId,
  language,
  openPopup,
  setOpenPopup,
  userIds,
}: CohortUsersProgressReportPopupProps) => {
  const [downloadingProgressReport, setDownloadingProgressReport] = useState(false);
  const [reportParams, setReportParams] = useState<ReportParams>({
    cohortId: Number(cohortId),
    userIds: [],
    sections: ["modules_exams", "certifications", "project_info", "attendance", "log_time"],
  });

  useEffect(() => {
    setReportParams((prevParams) => ({
      ...prevParams,
      userIds: userIds,
    }));
  }, [userIds]);

  const _isSectionEnabled = (section: string) => reportParams.sections?.includes(section) ?? false;
  const _isLogTimeFormatEnabled = (logTimeFormat: string) =>
    reportParams.logTimeFormats?.includes(logTimeFormat) ?? false;

  const _toggleSelectedReportSection = (section: string) => {
    const currentSections = reportParams.sections ?? [];
    setReportParams((params) => ({
      ...params,
      sections: currentSections.includes(section)
        ? currentSections.filter((selectedSection) => selectedSection !== section)
        : [...currentSections, section],
    }));
  };

  const _toggleSelectedReportLogTimeFormat = (logTimeFormat: string) => {
    const currentLogTimeFormats = reportParams.logTimeFormats ?? [];
    setReportParams((params) => ({
      ...params,
      logTimeFormats: currentLogTimeFormats.includes(logTimeFormat)
        ? currentLogTimeFormats.filter((selectedFormat) => selectedFormat !== logTimeFormat)
        : [...currentLogTimeFormats, logTimeFormat],
    }));
  };

  return (
    <Popup
      show={openPopup}
      onClose={() => setOpenPopup(false)}
      modalTitle={translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.TITLE")}
    >
      <div className="mt-3 m-regular">
        <FormControl
          type="text"
          placeholder={translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.PLACEHOLDER")}
          className="mb-3"
          onChange={(e) => setReportParams((params) => ({ ...params, cursusName: e.target.value }))}
        />
        <div className="mb-3">
          <h6>{translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.SELECT_SECTIONS")}</h6>
          <FormCheck
            id="modules-exams"
            label={
              <label htmlFor="modules-exams">
                {translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.SECTIONS.MODULES_EXAMS")}
              </label>
            }
            checked={_isSectionEnabled("modules_exams")}
            onChange={() => _toggleSelectedReportSection("modules_exams")}
          />
          <FormCheck
            id="certifications"
            label={
              <label htmlFor="certifications">
                {translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.SECTIONS.CERTIFICATIONS")}
              </label>
            }
            checked={_isSectionEnabled("certifications")}
            onChange={() => _toggleSelectedReportSection("certifications")}
          />
          <FormCheck
            id="project-info"
            label={
              <label htmlFor="project-info">
                {translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.SECTIONS.PROJECT_INFO")}
              </label>
            }
            checked={_isSectionEnabled("project_info")}
            onChange={() => _toggleSelectedReportSection("project_info")}
          />
          <FormCheck
            id="attendance"
            label={
              <label htmlFor="attendance">
                {translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.SECTIONS.ATTENDANCE")}
              </label>
            }
            checked={_isSectionEnabled("attendance")}
            onChange={() => _toggleSelectedReportSection("attendance")}
          />
          <FormCheck
            id="log-time"
            label={
              <label htmlFor="log-time">
                {translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.SECTIONS.LOG_TIME")}
              </label>
            }
            checked={_isSectionEnabled("log_time")}
            onChange={() => _toggleSelectedReportSection("log_time")}
          />
          {_isSectionEnabled("log_time") && (
            <div className="mt-2 ms-3">
              <FormCheck
                id="log-time-daily"
                label={
                  <label htmlFor="log-time-daily">
                    {translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.SECTIONS.LOG_TIME_DAILY")}
                  </label>
                }
                checked={_isLogTimeFormatEnabled("daily")}
                onChange={() => _toggleSelectedReportLogTimeFormat("daily")}
              />
              <FormCheck
                id="log-time-monthly"
                label={
                  <label htmlFor="log-time-monthly">
                    {translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.SECTIONS.LOG_TIME_MONTHLY")}
                  </label>
                }
                checked={_isLogTimeFormatEnabled("monthly")}
                onChange={() => _toggleSelectedReportLogTimeFormat("monthly")}
              />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-end mt-3">
          <DstButton
            value={translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.CONFIRM_BUTTON")}
            loading={downloadingProgressReport}
            disabled={downloadingProgressReport || !reportParams.sections.length}
            clickFunction={() =>
              downloadProgressReport(language, reportParams, setDownloadingProgressReport, () => {
                setReportParams({
                  cohortId: Number(cohortId),
                  userIds: [],
                  sections: ["modules_exams", "certifications", "project_info", "attendance", "log_time"],
                });
                setOpenPopup(false);
              })
            }
          />
        </div>
      </div>
    </Popup>
  );
};

export default CohortUsersProgressReportPopup;
