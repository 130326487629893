import ApiService from "../../../../../services/ApiService";
import translate from "../../../../../services/Translate";

export const downloadProgressReport = (
  language: string,
  params: {
    cohortId: number;
    cursusName?: string;
    userIds: number[];
    sections: string[];
    logTimeFormats?: string[];
  },
  setDownloadingProgressReport: Function,
  successCallback?: Function
) => {
  setDownloadingProgressReport(true);
  ApiService.postDownload(`cohorts/${params.cohortId}/users/progress/batch`, {
    cursus_name: params.cursusName ?? "",
    user_ids: params.userIds,
    sections: params.sections,
    log_time_formats: params.logTimeFormats ?? [],
  })
    .then((resp) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([resp.data], { type: "application/zip" }));
      link.setAttribute("download", `${params.cohortId}_progress_report.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      successCallback?.();
    })
    .catch((err) => {
      console.error(err);
      alert(translate(language, "PAGES.COHORT.USERS.MODALS.PROGRESS_REPORT.ALERTS.ERROR"));
    })
    .finally(() => setDownloadingProgressReport(false));
};
