import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";
import { FetchMeetingsParams, MeetingTypes, OrganizerAndAnimator } from "./Meetings.d";

/**
 * Will convert a hours range into local time
 * @param hoursRange hours range string
 * @param date (optional) origin date (helpful to avoid problem with daylight saving time)
 * @returns hours range string with local timezone
 * @example
 * getLocalHour("10:00 - 12:00") // "12:00 - 14:00" if we are in UTC+2
 * getLocalHour("07:30 - 09:30", "Fri, 28 Mar 2025 07:30:00 GMT") // "08:30 - 10:30" if we are in UTC+1 (winter)
 * getLocalHour("14:00 - 15:00", "Mon, 07 Apr 2025 14:00:00 GMT") // "16:00 - 17:00" if we are in UTC+1 (summer)
 */
export const getLocalHour = (hoursRange: string, date?: string) => {
  const offsetHours = (-1 * (date ? new Date(date) : new Date()).getTimezoneOffset()) / 60;

  return hoursRange
    .split(" - ")
    .map((hour) => hour.replace(/(\d+):(\d+)/gm, (_, hours, minutes) => `${(+hours + offsetHours) % 24}:${minutes}`))
    .join(" - ");
};

export const fetchMeetingMetaTypes = (
  setMeetingTypes: Function,
  setLoading: Function,
  language: string,
  onFail?: Function
) => {
  setLoading(true);
  ApiService.get("meetings/types")
    .then((response) =>
      setMeetingTypes(response.data.meeting_meta_types.map((type: MeetingTypes) => ({ value: type, display: type })))
    )
    .catch((err) => {
      console.error("Error fetching meeting types:", err);
      alert(translate(language, "PAGES.MEETINGS.ERROR.FETCH_MEETING_TYPES"));
      onFail?.();
    })
    .finally(() => setLoading(false));
};

export const fetchResources = (setOrganizers: Function, setAnimators: Function, language: string) => {
  ApiService.get("meetings/resources")
    .then((response) => {
      setOrganizers(
        response.data?.meeting_staff_and_programs?.organizers.map((org: OrganizerAndAnimator) => ({
          value: org.username,
          display: org.full_name,
        }))
      );

      setAnimators(
        response.data?.meeting_staff_and_programs?.animators.map((ani: OrganizerAndAnimator) => ({
          value: ani.username,
          display: ani.full_name,
        }))
      );
    })
    .catch((err) => {
      console.error("Error fetching meeting resources:", err.response || err);
      alert(translate(language, "PAGES.MEETINGS.ERROR.RESOURCES"));
    });
};

export const fetchMeetings = (
  params: URLSearchParams,
  setMeetings: Function,
  setFilteredMeetings: Function,
  setLoading: Function,
  language: string
) => {
  setLoading(true);
  ApiService.get(`meetings?${params.toString()}`)
    .then((response) => {
      setMeetings(response.data.meetings);
      setFilteredMeetings(response.data.meetings);
    })
    .catch((err) => {
      console.error("Error fetching meetings:", err);
      alert(translate(language, "PAGES.MEETINGS.ERROR.FETCH"));
    })
    .finally(() => {
      setLoading(false);
    });
};

export const buildParamsAndFetchMeetings = ({
  startDate,
  endDate,
  selectedMeetingTypes,
  selectedOrganizers,
  selectedAnimators,
  showUnassigned,
  language,
  setMeetings,
  setFilteredMeetings,
  setLoading,
}: FetchMeetingsParams) => {
  const params = new URLSearchParams();

  if (startDate) params.append("start_date", `${startDate}T00:00:00+00:00`);
  if (endDate) params.append("end_date", `${endDate}T23:59:59+00:00`);
  if (selectedMeetingTypes.length > 0) params.append("meeting_meta_types", selectedMeetingTypes.join(","));
  if (selectedOrganizers.length > 0) params.append("organizers_id", selectedOrganizers.filter(Boolean).join(","));
  if (selectedAnimators.length > 0) params.append("animators_id", selectedAnimators.filter(Boolean).join(","));
  if (showUnassigned) params.append("show_unassigned", "true");

  setLoading(true);
  fetchMeetings(params, setMeetings, setFilteredMeetings, setLoading, language);
};
