import ApiService from "../../../../services/ApiService";
import { getUTCTime } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";

export const fetchSprint = (cohortId: string, language: string, setSprints: Function, setLoading: Function) => {
  setLoading(true);
  ApiService.get(`cohorts/${cohortId}/sprints`)
    .then((resp) => setSprints(resp.data.sprints || []))
    .catch((error) => {
      setSprints([]);
      console.error(error);
      alert(translate(language, "PAGES.COHORT.SPRINT.ERROR_FETCH"));
    })
    .finally(() => setLoading(false));
};

export const fetchModules = (setModules: Function, setLoadingModules: Function) => {
  setLoadingModules(true);
  ApiService.get("modules?get_deprecated=false")
    .then((response) => setModules(response.data.return.modules))
    .catch((err) => {
      console.error("An error occurred while fetching modules", err);
    })
    .finally(() => setLoadingModules(false));
};

export const handleStateChange = (index: number, newValue: string, setStateArray: Function) => {
  setStateArray((prevArray: string[]) => {
    const newStateArray = [...prevArray];
    newStateArray[index] = newValue;
    return newStateArray;
  });
};

export const handleOptionalChange = (
  sprintIndex: number,
  lessonIndex: number,
  optionalStatus: any[],
  setOptionalStatus: Function
) => {
  const newOptionalStatus = [...optionalStatus];
  newOptionalStatus[sprintIndex][lessonIndex].isOptional = !newOptionalStatus[sprintIndex][lessonIndex].isOptional;
  setOptionalStatus(newOptionalStatus);
};

export const saveSprints = (
  cohortId: string,
  inputValues: string[],
  startDates: string[],
  endDates: string[],
  optionalStatus: any[],
  language: string,
  callback?: Function
) => {
  const newSprints = inputValues.map((sprintName: string, index: number) => ({
    name: sprintName,
    start: ~~(getUTCTime(startDates[index]) / 1000),
    end: ~~(getUTCTime(endDates[index]) / 1000),
    modules: optionalStatus[index].map((moduleData: any) => ({
      id: moduleData.id,
      is_mandatory: !moduleData.isOptional,
    })),
  }));

  ApiService.put(`cohorts/${cohortId}/sprints`, { sprints: newSprints })
    .then(() => {
      callback?.();
    })
    .catch((error) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.SPRINT.SAVE_ERROR"));
    });
};
