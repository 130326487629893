import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";

import DstButton from "../../../../../components/DstButton/DstButton";
import MultiSelectDropdown from "../../../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Popup from "../../../../../components/Popup/Popup";
import translate from "../../../../../services/Translate";
import { fetchUsers } from "../CohortUsers.function";
import { AddModulesPopupProps } from "./AddModulesPopup.d";
import { addUsersModules, fetchModules } from "./AddModulesPopup.function";
import "./AddModulesPopup.scss";

const AddModulesPopup = ({
  show,
  closePopup,
  cohortId,
  usersSelected,
  setCohortUsers,
  setUsersSelected,
  language,
}: AddModulesPopupProps) => {
  const [loadingConfirmModules, setLoadingConfirmModules] = useState(false);
  const [selectedModules, setSelectedModules] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      fetchModules(setModules, setLoading, language);
    }
  }, [show, language]);

  return (
    <Popup
      show={show}
      onClose={closePopup}
      modalTitle={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_MODULE.TITLE")}
      footerContent={
        <React.Fragment>
          <DstButton
            variant="secondary"
            clickFunction={() => {
              setSelectedModules([]);
              closePopup();
            }}
            value={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_MODULE.BUTTONS.CANCEL")}
          />
          <DstButton
            variant="primary"
            clickFunction={() => {
              setLoadingConfirmModules(true);
              addUsersModules(
                cohortId,
                usersSelected.map((user) => user.id),
                selectedModules,
                language,
                setLoadingConfirmModules,
                () => {
                  fetchUsers(cohortId, setCohortUsers, language, () => setUsersSelected([]));
                  closePopup();
                  setSelectedModules([]);
                }
              );
            }}
            disabled={!selectedModules.length || loadingConfirmModules}
            value={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_MODULE.BUTTONS.CONFIRM")}
            loading={loadingConfirmModules}
          />
        </React.Fragment>
      }
    >
      <React.Fragment>
        <MultiSelectDropdown
          label={translate(language, "PAGES.COHORT.USERS.MODALS.ADD_MODULE.SELECT_MODULES")}
          items={modules}
          displayItem={(item) => item.name}
          badgeItemProperty="name"
          language={language}
          onConfirm={(selectedItems) => setSelectedModules(selectedItems.map((item) => item.id))}
          toggleClass="w-100"
          borderToggle
          mock={loading}
          btnWidth="100%"
        />

        {!loading && modules.length > 0 && selectedModules.length > 0 && (
          <div className="mt-3" id="selected-modules">
            {selectedModules.map((moduleId) => (
              <Badge
                key={`module-id-${moduleId}`}
                pill
                className="me-1 d-inline-flex w-fit align-items-center"
                bg="dropdown"
              >
                <span>{modules.find((module: any) => module.id === moduleId)?.name}</span>
              </Badge>
            ))}
            <div className="xs-regular mt-2">
              {translate(
                language,
                selectedModules.length > 1
                  ? "PAGES.COHORT.USERS.MODALS.ADD_MODULE.ITEMS_SELECTED"
                  : "PAGES.COHORT.USERS.MODALS.ADD_MODULE.ITEM_SELECTED"
              ).replace("{{COUNT}}", String(selectedModules.length))}
            </div>
          </div>
        )}

        {!loading && modules.length === 0 && (
          <p className="text-center">{translate(language, "PAGES.COHORT.USERS.MODALS.ADD_MODULE.NO_MODULES")}</p>
        )}
      </React.Fragment>
    </Popup>
  );
};

export default AddModulesPopup;
