import translate from "../../../../services/Translate";

export const openRecordLink = (recordLink: any, language: string) => {
  if (window.confirm(translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.RECORD_LINK.ALERT_MESSAGE"))) {
    navigator.clipboard.writeText(recordLink.password);
    window.open(recordLink.share_url, "_blank");
  }
};

export const copyMeetingLink = (meeting_link: string, language: string) => {
  navigator.clipboard.writeText(meeting_link).then(
    () => alert(translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MEETING_LINK.COPY_LINK")),
    (err) => console.error(translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MEETING_LINK.COPY_ERROR"), err)
  );
};

export const copyRecordLinkMessage = (recordLink: any, language: string) => {
  navigator.clipboard.writeText(`Share URL : ${recordLink.share_url}\nPassword : ${recordLink.password}`).then(
    () => alert(translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.RECORD_LINK.COPY_MESSAGE")),
    (err) => console.error(translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.RECORD_LINK.COPY_ERROR"), err)
  );
};
