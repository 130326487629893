import { useEffect, useState } from "react";
import { Col, Dropdown, DropdownButton, Form, Placeholder, Row } from "react-bootstrap";

import DstButton from "../../components/DstButton/DstButton";
import MultiFilterDropdown from "../../components/MultiFilterDropdown/MultiFilterDropdown";
import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";
import { LoadedPermissionsData } from "./Permissions.d";
import {
  closeRolePopup,
  closeRoutePopup,
  createNewRole,
  duplicateRole,
  fetchPermissionsData,
  fetchRoleUsers,
  getPermissionsWithGodMode,
  handleDeleteRole,
  handleSelectAllPermissions,
  handleUnselectAllPermissions,
  openRolePopup,
  openRoutePopup,
  updateRole,
} from "./Permissions.functions";
import "./Permissions.scss";
import { rolePopup, routePopup } from "./components/Popups";

const METHODS = ["GET", "POST", "PUT", "PATCH", "DELETE"];

const ALL_RIGHTS_KEY = "*_/**";

const _handlePermissionSelect = (
  roleId: string,
  route: string,
  method: string,
  rolePermissions: { [roleId: string]: string[] },
  setRolePermissions: Function
) => {
  let currentPermissions = rolePermissions[roleId] || [];
  const permissionKey = `${method}_${route}`;

  if (
    (permissionKey !== ALL_RIGHTS_KEY && currentPermissions.includes(ALL_RIGHTS_KEY)) ||
    permissionKey === ALL_RIGHTS_KEY
  ) {
    currentPermissions = [];
  }
  setRolePermissions({
    ...rolePermissions,
    [roleId]: currentPermissions.includes(permissionKey)
      ? currentPermissions.filter((permission) => permission !== permissionKey)
      : [...currentPermissions, permissionKey],
  });
};

const Permissions = () => {
  const { language } = useSession();
  const [selectedRole, setSelectedRole] = useState<any | null>();
  const [roles, setRoles] = useState<{ id: string; description: string; users?: number[] }[]>([]);
  const [permissions, setPermissions] = useState<{ [route: string]: any }>({});
  const [tags, setTags] = useState([]);
  const [showPopups, setShowPopups] = useState<{ [key: string]: number | string } | null>(null);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [selectedMethods, setSelectedMethods] = useState<string[]>([]);
  const [roleUsers, setRoleUsers] = useState<{ [roleId: string]: number[] }>({});
  const [loaded, setLoaded] = useState<LoadedPermissionsData>({
    roles: false,
    permissions: false,
    tags: false,
    rolesUsers: {},
  });
  const [displayPage, setDisplayPage] = useState(0);
  const [rolePermissions, setRolePermissions] = useState<{ [roleId: string]: string[] }>({});

  const _fetchPermissionsData = () =>
    fetchPermissionsData(setRoles, setRolePermissions, setPermissions, setTags, setSelectedRole, setLoaded);

  useEffect(() => {
    _fetchPermissionsData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisplayPage(0);
  }, [selectedRole]);

  useEffect(() => {
    if (selectedRole) {
      fetchRoleUsers(String(selectedRole.id), setRoleUsers, setLoaded, displayPage);
    }
  }, [selectedRole, displayPage]);

  const filteredPermissions = Object.entries(permissions).filter(([route, data]) => {
    const tagsMatch = selectedTags.every((tag) =>
      data
        .map((dataMethod: any) => dataMethod?.documentation?.tags)
        .flat()
        .includes(tag)
    );
    const methodsMatch =
      selectedMethods.length === 0 ||
      selectedMethods.some((method) => data.map((dataMethod: any) => dataMethod.method).includes(method));
    return tagsMatch && methodsMatch;
  });

  return (
    <div id="permissions">
      <div className="d-flex justify-content-between px-3 pt-3">
        <h1 className="mt-3">{translate(language, "PAGES.PERMISSIONS.TITLE")}</h1>
        <img src="/assets/images/person-h-f.svg" alt="A man and a woman standing next to each other" />
      </div>
      <div className="border-top">
        <Row className="mx-0">
          <Col xs={6} sm={3} md={3} lg={3} xl={2} className="px-0 pt-4 min-h-100vh border-end">
            <h2 className="px-3 pb-3">{translate(language, "PAGES.PERMISSIONS.TAB_TITLE")}</h2>
            {loaded.roles ? (
              roles.map((role: any) => (
                <div
                  key={role.id}
                  className={`border-bottom p-2 px-md-3 clickable role-selecter ${
                    selectedRole && role.id === selectedRole.id ? "active" : ""
                  }`}
                  onClick={() => setSelectedRole(role)}
                >
                  <div className="d-flex w-100 justify-content-between">
                    <div>
                      <i className="dst-icon-users-01 me-2" />
                      {role.name}
                    </div>
                    <div className="align-content-center">
                      <i className="dst-icon-chevron-right" />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Placeholder as="div" animation="glow" role="mockup">
                {[...Array(10)].map((_, index) => (
                  <div key={`mock-role-selector-${index}`} className="border-bottom p-2 px-md-3 role-selecter">
                    <div className="d-flex w-100 justify-content-between">
                      <Placeholder xs={10} role="mockup" />
                    </div>
                  </div>
                ))}
              </Placeholder>
            )}
            <DstButton
              value={translate(language, "PAGES.PERMISSIONS.CREATE_ROLE_BUTTON_TITLE")}
              variant="light"
              backgroundColor="var(--color-medium-gray)"
              color="var(--color-black)"
              btnClass="w-fit my-3 custom-btn-role m-3"
              btnImageBefore={<i className="dst-icon-plus-circle me-2" />}
              clickFunction={() => createNewRole(language, _fetchPermissionsData)}
            />
          </Col>

          <Col>
            {selectedRole ? (
              <div className="permissions-tab">
                <Row className="permissions-panel">
                  <Col xl={9} className="pt-4 h-100">
                    <Row className="permissions-panel-header">
                      <Col>
                        <h2>
                          {selectedRole.name}
                          {translate(language, "PAGES.PERMISSIONS.TABS.TITLE")}
                        </h2>
                        <div className="d-flex gap-2">
                          <MultiFilterDropdown
                            language={language}
                            tags={tags}
                            onSelectTags={(selectedItems: Array<any>) => {
                              setSelectedTags(selectedItems);
                            }}
                            mock={!loaded.tags}
                            initialStateText={translate(
                              language,
                              "PAGES.PERMISSIONS.MULTI_FILTER_DROPDOWN.INITIAL_TEXT"
                            )}
                            inputTitle={translate(language, "PAGES.PERMISSIONS.MULTI_FILTER_DROPDOWN.TITLE")}
                            inputLabel={translate(language, "PAGES.PERMISSIONS.MULTI_FILTER_DROPDOWN.INPUT_LABEL")}
                            inputPlaceholder={translate(
                              language,
                              "PAGES.PERMISSIONS.MULTI_FILTER_DROPDOWN.INPUT_PLACEHOLDER"
                            )}
                            showSearchBar
                          />
                          <MultiFilterDropdown
                            tags={METHODS}
                            onSelectTags={(selectedItems: (string | number)[]) =>
                              setSelectedMethods(selectedItems as string[])
                            }
                            mock={!loaded.permissions}
                            inputTitle={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.SELECT_METHODS")}
                            language={language}
                          />
                        </div>
                      </Col>
                      <Col className="text-end">
                        <div>
                          <DstButton
                            backgroundColor="var(--color-off-white)"
                            color="var(--color-black)"
                            variant="light"
                            value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.SAVE_ROLE")}
                            btnImageBefore={<i className="dst-icon-save-01 me-2" />}
                            clickFunction={() =>
                              updateRole(
                                language,
                                selectedRole.id,
                                rolePermissions[String(selectedRole.id)],
                                _fetchPermissionsData
                              )
                            }
                          />
                          <DstButton
                            backgroundColor="var(--color-off-white)"
                            color="var(--color-black)"
                            variant="light"
                            value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.DUPLICATE_ROLE")}
                            btnImageBefore={<i className="dst-icon-copy-07 me-2" />}
                            btnClass="ms-2"
                            clickFunction={() => duplicateRole(language, selectedRole, _fetchPermissionsData)}
                          />
                          <DstButton
                            backgroundColor="var(--color-off-white)"
                            color="var(--color-black)"
                            variant="light"
                            value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.RIGHTS_SUMMARY")}
                            btnImageBefore={<i className="dst-icon-file-02 me-2" />}
                            btnClass="ms-2"
                            clickFunction={() => openRolePopup(setShowPopups, String(selectedRole.id))}
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <DropdownButton
                            id="dropdown-check-method"
                            title={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.CHOOSE_ALL")}
                            variant="light"
                            className="s-bold mt-2 ms-2"
                          >
                            {METHODS.map((method) => (
                              <Dropdown.Item
                                key={method}
                                onClick={() =>
                                  handleSelectAllPermissions(
                                    method,
                                    rolePermissions,
                                    selectedRole.id,
                                    permissions,
                                    selectedTags,
                                    setRolePermissions
                                  )
                                }
                              >
                                {method}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                          <DropdownButton
                            id="dropdown-check-method"
                            title={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.UNCHOOSE_ALL")}
                            variant="light"
                            className="s-bold mt-2 ms-2"
                          >
                            {METHODS.map((method) => (
                              <Dropdown.Item
                                key={method}
                                onClick={() =>
                                  handleUnselectAllPermissions(
                                    method,
                                    rolePermissions,
                                    selectedRole.id,
                                    permissions,
                                    selectedTags,
                                    setRolePermissions
                                  )
                                }
                              >
                                {method}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                        </div>
                      </Col>
                    </Row>
                    {loaded.permissions ? (
                      <div className="permissions-list overflow-auto">
                        <div className="d-flex my-3 justify-content-between">
                          <div className="d-flex">
                            <DstButton
                              variant="outline-primary"
                              value=""
                              btnImageAfter={<i className="dst-icon-info-circle" />}
                              btnClass="d-flex fw-bolder me-3 px-2"
                              clickFunction={() => openRoutePopup(setShowPopups, "/**")}
                            />
                            <div className="red-bright-font tinted-red-background rounded border px-2">{"/**"}</div>
                          </div>
                          <Form className="d-flex">
                            <Form.Group controlId={`formBasicCheckbox_god_mode`} className="d-flex">
                              <Form.Check
                                type="checkbox"
                                label="All"
                                id={`role-${selectedRole.id}-checkbox-god-mode`}
                                className="w-fit me-3 align-self-center checkbox-style"
                                onChange={() =>
                                  _handlePermissionSelect(
                                    selectedRole.id,
                                    "/**",
                                    "*",
                                    rolePermissions,
                                    setRolePermissions
                                  )
                                }
                                checked={rolePermissions[String(selectedRole.id)]?.includes(ALL_RIGHTS_KEY)}
                              />
                            </Form.Group>
                          </Form>
                        </div>
                        {filteredPermissions.map(([route, data]) => (
                          <div key={route} className="d-flex my-3 justify-content-between">
                            <div className="d-flex">
                              <DstButton
                                value=""
                                variant="outline-primary"
                                btnImageAfter={<i className="dst-icon-info-circle" />}
                                btnClass="d-flex fw-bolder me-3 px-2"
                                clickFunction={() => openRoutePopup(setShowPopups, route)}
                              />
                              <div className="red-bright-font tinted-red-background rounded border px-2">{route}</div>
                            </div>
                            <Form className="d-flex">
                              <Form.Group controlId={`formBasicCheckbox_${route}`} className="d-flex">
                                {METHODS.map((method: any) => (
                                  <Form.Check
                                    key={`${route}_${method}`}
                                    type="checkbox"
                                    label={method}
                                    id={`role-${selectedRole.id}-${method}-checkbox-${route}`}
                                    className="w-fit me-3 align-self-center checkbox-style"
                                    disabled={!data.map((elem: any) => elem.method).includes(method)}
                                    onChange={() =>
                                      _handlePermissionSelect(
                                        selectedRole.id,
                                        route,
                                        method,
                                        rolePermissions,
                                        setRolePermissions
                                      )
                                    }
                                    checked={rolePermissions[String(selectedRole.id)]?.includes(`${method}_${route}`)}
                                  />
                                ))}
                              </Form.Group>
                            </Form>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Placeholder animation="glow">
                        {[...Array(10)].map((_, index: number) => (
                          <Placeholder key={`mock-permission-line-${index}`} className="mt-3" xs={12} role="mockup" />
                        ))}
                      </Placeholder>
                    )}
                  </Col>
                  <Col className="pt-4 border-start min-h-100vh">
                    <h2>{translate(language, "PAGES.PERMISSIONS.TABS.USER_COLUMN.TITLE")}</h2>
                    <div className="span m-bold">
                      {translate(language, "PAGES.PERMISSIONS.TABS.USER_COLUMN.LIST_TITLE")}
                    </div>
                    {loaded.rolesUsers?.[selectedRole.id] ? (
                      <ul className="list-unstyled ps-0">
                        {roleUsers[selectedRole.id]?.length > 0 ? (
                          roleUsers[selectedRole.id].map((userId) => (
                            <li
                              key={userId}
                              className="megium-gray-background list-style-none w-fit border my-2 px-2 rounded"
                            >
                              {userId}
                            </li>
                          ))
                        ) : (
                          <p className="my-2 smoke-gray-font m-regular">
                            {translate(language, "PAGES.PERMISSIONS.TABS.USER_COLUMN.NO_USER")}
                          </p>
                        )}
                      </ul>
                    ) : (
                      <Placeholder as="div" animation="glow" role="mockup">
                        <Placeholder xs={10} role="mockup" />
                        <Placeholder xs={10} role="mockup" />
                        <Placeholder xs={10} role="mockup" />
                      </Placeholder>
                    )}
                    {loaded.rolesUsers?.[selectedRole.id] && displayPage < 5 && (
                      <div>
                        <DstButton
                          value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.LOAD_MORE")}
                          clickFunction={() => setDisplayPage((displayPage: number) => displayPage + 1)}
                          variant="secondary"
                          btnClass="mt-2 s-regular"
                        />
                      </div>
                    )}

                    <DstButton
                      backgroundColor="var(--color-medium-gray)"
                      color="var(--color-black)"
                      variant="light"
                      value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.ADD_NEW_USER")}
                      btnClass="mt-3"
                      btnImageBefore={<i className="dst-icon-plus-circle me-2" />}
                      clickFunction={() => alert(translate(language, "ERROR.DISABLED_FEATURE"))}
                    />
                    <div>
                      <DstButton
                        value={translate(language, "PAGES.PERMISSIONS.TABS.BUTTONS.DELETE_ROLE")}
                        variant="danger"
                        btnClass="mt-3"
                        btnImageBefore={<i className="dst-icon-trash-01 me-2" />}
                        clickFunction={() => handleDeleteRole(language, selectedRole.id, _fetchPermissionsData)}
                      />
                    </div>
                    {rolePopup(language, showPopups, () => closeRolePopup(setShowPopups), selectedRole)}
                  </Col>
                </Row>
              </div>
            ) : (
              <p className="text-center silver-gray-font mt-3">{translate(language, "PAGES.PERMISSIONS.NO_ROLE")}</p>
            )}
          </Col>
        </Row>
        {routePopup(language, showPopups, () => closeRoutePopup(setShowPopups), getPermissionsWithGodMode(permissions))}
      </div>
    </div>
  );
};

export default Permissions;
