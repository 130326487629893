import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Placeholder, Row, Tooltip } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import DstTable from "../../components/DstTable/DstTable";
import DstTableSearch from "../../components/DstTable/DstTableSearch/DstTableSearch";
import MultiFilterDropdown from "../../components/MultiFilterDropdown/MultiFilterDropdown";
import { useSession } from "../../contexts/SessionContext";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate } from "../../services/UtilityService";
import { Meeting } from "./Meetings.d";
import { buildParamsAndFetchMeetings, fetchMeetingMetaTypes, fetchResources, getLocalHour } from "./Meetings.function";
import "./Meetings.scss";
import MeetingCreationModal from "./components/CreationModal/CreationModal";

const Meetings = () => {
  const { language } = useSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [meetingTypesFetchSucceeded, setMeetingTypesFetchSucceeded] = useState(false);
  const [filteredMeetings, setFilteredMeetings] = useState<Meeting[]>([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<string | null>(searchParams.get("start_date") || null);
  const [endDate, setEndDate] = useState<string | null>(searchParams.get("end_date") || null);
  const [tempStartDate, setTempStartDate] = useState<string | null>(startDate);
  const [tempEndDate, setTempEndDate] = useState<string | null>(endDate);
  const [selectedMeetings, setSelectedMeetings] = useState<string[]>([]);
  const [organizers, setOrganizers] = useState<{ value: string; display: string }[]>([]);
  const [animators, setAnimators] = useState<{ value: string; display: string }[]>([]);
  const [meetingTypes, setMeetingTypes] = useState<{ value: string; display: string }[]>([]);
  const [selectedOrganizers, setSelectedOrganizers] = useState<string[]>(
    searchParams.get("organizers")?.split(",") || []
  );
  const [selectedAnimators, setSelectedAnimators] = useState<string[]>(searchParams.get("animators")?.split(",") || []);
  const [selectedMeetingTypes, setSelectedMeetingTypes] = useState<string[]>(
    searchParams.get("meeting_meta_types")?.split(",") || []
  );
  const [showUnassigned, setShowUnassigned] = useState(searchParams.get("show_unassigned") === "true");
  const [showOverlay, setShowOverlay] = useState(true);
  const navigate = useNavigate();
  const [showModalCreation, setShowModalCreation] = useState(false);

  useEffect(() => {
    if (searchParams.get("start_date") || searchParams.get("end_date")) {
      setShowOverlay(false);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchResources(setOrganizers, setAnimators, language);

    fetchMeetingMetaTypes(setMeetingTypes, setLoading, language, () => {
      buildParamsAndFetchMeetings({
        startDate,
        endDate,
        selectedMeetingTypes,
        selectedOrganizers,
        selectedAnimators,
        showUnassigned,
        language,
        setMeetings,
        setFilteredMeetings,
        setLoading,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (meetingTypes.length > 0) {
      const allValues = meetingTypes.map((type) => type.value);

      setSelectedMeetingTypes((prev) => {
        if (prev.length === 0) {
          return allValues.filter((type) => !["partner", "call_projet"].includes(type));
        } else {
          return prev.filter((type) => allValues.includes(type));
        }
      });

      setMeetingTypesFetchSucceeded(true);
    }
  }, [meetingTypes]);

  useEffect(() => {
    if (showUnassigned) {
      const unassignedMeetings = meetings.filter((meeting) => !meeting.animators || meeting.animators.length === 0);
      setFilteredMeetings(unassignedMeetings);
    } else {
      setFilteredMeetings(meetings);
    }
  }, [showUnassigned, meetings]);

  useEffect(() => {
    if (!meetingTypesFetchSucceeded) return;
    buildParamsAndFetchMeetings({
      startDate,
      endDate,
      selectedMeetingTypes,
      selectedOrganizers,
      selectedAnimators,
      showUnassigned,
      language,
      setMeetings,
      setFilteredMeetings,
      setLoading,
    });
  }, [
    startDate,
    endDate,
    selectedMeetingTypes,
    selectedOrganizers,
    selectedAnimators,
    showUnassigned,
    language,
    meetingTypesFetchSucceeded,
  ]);

  useEffect(() => {
    const params: Record<string, string> = {};

    if (startDate) params.start_date = startDate;
    if (endDate) params.end_date = endDate;
    if (selectedMeetingTypes.length > 0) params.meeting_meta_types = selectedMeetingTypes.join(",");
    if (selectedOrganizers.length > 0) params.organizers = selectedOrganizers.join(",");
    if (selectedAnimators.length > 0) params.animators = selectedAnimators.join(",");
    if (showUnassigned) params.show_unassigned = "true";

    setSearchParams(params);
  }, [
    startDate,
    endDate,
    selectedMeetingTypes,
    selectedOrganizers,
    selectedAnimators,
    showUnassigned,
    setSearchParams,
  ]);

  const columns = [
    {
      key: "start",
      name: translate(language, "PAGES.MEETINGS.TABLE_HEADER.START_DATE"),
      sortCompute: (item: Meeting) => (item.start ? new Date(item.start).getTime() : 0),
      render: (item: Meeting) => formatDateHumanly(language, item.start),
    },
    {
      key: "hours_range",
      name: translate(language, "PAGES.MEETINGS.TABLE_HEADER.TIME"),
      render: (item: Meeting) => getLocalHour(item.hours_range, item.start),
    },
    { key: "meeting_name", name: translate(language, "PAGES.MEETINGS.TABLE_HEADER.MEETING_NAME") },
    {
      key: "meeting_type.meeting_meta_type",
      name: translate(language, "PAGES.MEETINGS.TABLE_HEADER.MEETING_TYPE"),
      render: (item: Meeting) => item.meeting_type?.meeting_meta_type || "N/A",
    },
    {
      key: "organizer",
      name: translate(language, "PAGES.MEETINGS.TABLE_HEADER.ORGANIZER"),
      render: (item: Meeting) =>
        item.organizer
          ? `${item.organizer.first_name || ""} ${item.organizer.last_name || ""}`.trim()
          : translate(language, "PAGES.MEETINGS.TABLE_BODY.NO_ORGANIZER"),
    },
    {
      key: "animators",
      name: translate(language, "PAGES.MEETINGS.TABLE_HEADER.ANIMATORS"),
      render: (item: Meeting) =>
        item.animators?.length
          ? item.animators.map((animator) => `${animator.first_name} ${animator.last_name}`).join(", ")
          : translate(language, "PAGES.MEETINGS.TABLE_BODY.NO_ANIMATORS"),
    },
  ];

  const searchableColumns = [
    "meeting_name",
    "start",
    "meeting_type.meeting_meta_type",
    "organizer.first_name",
    "organizer.last_name",
  ];

  return (
    <div className="p-3" id="meetings">
      <Row className="d-flex justify-content-between mb-3">
        <Col xl className="d-flex align-items-center">
          <div className="d-flex align-items-center me-3">
            <h1 className="mb-0 h2">
              <span className="me-1">{translate(language, "PAGES.MEETINGS.TITLE")}</span>
              {loading ? (
                <Placeholder as="span" animation="glow" className="placeholder" style={{ width: 70 }}>
                  <Placeholder xs={12} />
                </Placeholder>
              ) : (
                `(${filteredMeetings.length})`
              )}
            </h1>
            {showOverlay && (
              <OverlayTrigger
                key="overlay-trigger-deadline-info"
                placement="bottom"
                overlay={
                  <Tooltip aria-label="description">
                    {translate(language, "PAGES.MEETINGS.OVERLAY_DESCRIPTION")}
                  </Tooltip>
                }
              >
                <i className="clickable dst-icon-info-circle ms-2" />
              </OverlayTrigger>
            )}
          </div>
          <DstTableSearch
            data={meetings}
            searchableColumns={searchableColumns}
            onSearch={(filtered: Meeting[]) => setFilteredMeetings(filtered)}
          />
        </Col>
        <Col xl className="d-flex align-items-center justify-content-xl-end mt-3 md-xl-0">
          <div className="bg-light rounded me-2 p-2 s-bold align-content-center">
            <Form.Check
              type="checkbox"
              id="unassigned-checkbox"
              label={`${translate(language, "PAGES.MEETINGS.FILTERS.UNASSIGNED_MEETINGS")} (${
                meetings.filter((m) => !m.animators?.length).length
              })`}
              checked={showUnassigned}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowUnassigned(e.target.checked)}
            />
          </div>
          <DstButton
            btnClass="me-2"
            variant="light"
            value={translate(language, "PAGES.MEETINGS.BUTTONS.IMPORT_CSV")}
            btnImageBefore={<i className="me-2 dst-icon-download-03" />}
            clickFunction={() => navigate("/meetings/import")}
          />
          <DstButton
            value={translate(language, "PAGES.MEETINGS.BUTTONS.CREATE_MEETING")}
            btnImageBefore={<i className="me-2 dst-icon-plus" />}
            clickFunction={() => setShowModalCreation(true)}
          />
          <MeetingCreationModal
            show={showModalCreation}
            closeModal={() => setShowModalCreation(false)}
            language={language}
          />
          <DstButton
            clickFunction={() =>
              buildParamsAndFetchMeetings({
                startDate,
                endDate,
                selectedMeetingTypes,
                selectedOrganizers,
                selectedAnimators,
                showUnassigned,
                language,
                setMeetings,
                setFilteredMeetings,
                setLoading,
              })
            }
            value=""
            btnClass="py-2 ms-2"
            btnImageBefore={<i className="dst-icon-refresh-cw-05" />}
            tooltip={translate(language, "PAGES.MEETINGS.BUTTONS.REFRESH")}
            variant="secondary"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={7} className="d-flex row mb-2 align-content-end">
          <div className="w-fit mt-1">
            <MultiFilterDropdown
              inputTitle={translate(language, "PAGES.MEETINGS.FILTERS.MEETING_TYPE")}
              tags={meetingTypes}
              onSelectTags={(tags) => setSelectedMeetingTypes(tags.map(String))}
              defaultValue={selectedMeetingTypes}
              buttonWidth="120px"
              showSearchBar
              language={language}
              mock={meetingTypes.length === 0}
            />
          </div>
          <div className="w-fit mt-1">
            <MultiFilterDropdown
              inputTitle={translate(language, "PAGES.MEETINGS.FILTERS.ORGANIZER")}
              tags={organizers}
              onSelectTags={(tags) => setSelectedOrganizers(tags.map(String))}
              defaultValue={selectedOrganizers}
              buttonWidth="120px"
              showSearchBar
              language={language}
              mock={organizers.length === 0}
            />
          </div>
          <div className="w-fit mt-1">
            <MultiFilterDropdown
              inputTitle={translate(language, "PAGES.MEETINGS.FILTERS.ANIMATOR")}
              tags={animators}
              onSelectTags={(tags) => setSelectedAnimators(tags.map(String))}
              defaultValue={selectedAnimators}
              buttonWidth="120px"
              showSearchBar
              language={language}
              mock={animators.length === 0}
            />
          </div>
          {(selectedMeetingTypes.length > 0 || selectedOrganizers.length > 0 || selectedAnimators.length > 0) && (
            <div
              className="ms-1 clickable dark-gray-font text-decoration-underline xs-regular w-fit align-content-center"
              onClick={() => {
                setSelectedMeetingTypes([]);
                setSelectedOrganizers([]);
                setSelectedAnimators([]);
              }}
            >
              {translate(language, "PAGES.MEETINGS.FILTERS.CLEAR_FILTERS")}
            </div>
          )}
        </Col>
        <Col className="d-flex align-items-end justify-content-lg-end mb-2">
          <div className="me-2">
            <label htmlFor="start-date" className="form-label dark-gray-font xs-regular mb-1">
              {translate(language, "PAGES.MEETINGS.FILTERS.START_DATE")}
            </label>
            <input
              type="date"
              id="start-date"
              className="form-control s-regular"
              value={tempStartDate || ""}
              onChange={(e) => setTempStartDate(e.target.value)}
            />
          </div>
          <div className="me-2">
            <label htmlFor="end-date" className="form-label dark-gray-font xs-regular mb-1">
              {translate(language, "PAGES.MEETINGS.FILTERS.END_DATE")}
            </label>
            <input
              type="date"
              id="end-date"
              className="form-control s-regular"
              value={tempEndDate || ""}
              onChange={(e) => setTempEndDate(e.target.value)}
            />
          </div>
          <DstButton
            clickFunction={() => {
              setStartDate(tempStartDate);
              setEndDate(tempEndDate);
            }}
            value=""
            btnClass="py-2"
            btnImageBefore={<i className="dst-icon-search-md" />}
            tooltip={translate(language, "PAGES.MEETINGS.BUTTONS.APPLY_DATES")}
          />
          <DstButton
            clickFunction={() => {
              setTempStartDate(null);
              setTempEndDate(null);
              setStartDate(null);
              setEndDate(null);
              setShowOverlay(true);
            }}
            value=""
            btnClass="py-2 ms-2"
            btnImageBefore={<i className="dst-icon-eraser" />}
            tooltip={translate(language, "PAGES.MEETINGS.BUTTONS.CLEAR_DATES")}
            variant="secondary"
          />
        </Col>
      </Row>
      <DstTable
        columns={columns}
        loading={loading}
        data={filteredMeetings}
        selectable
        selectedItems={selectedMeetings}
        onRowSelect={(selectedRows) => setSelectedMeetings(selectedRows)}
        onRowClick={(event, item) => completeNavigate(event, navigate, `/meeting/${item.id}`)}
        striped
        pagination
        paginationResizeOptions={[10, 50, 100]}
      />
    </div>
  );
};

export default Meetings;
