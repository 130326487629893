import ApiService from "../../../../../services/ApiService";
import translate from "../../../../../services/Translate";

export const addUsersModules = (
  cohortId: string,
  userIds: number[],
  moduleIds: number[],
  language: string,
  setLoading: Function,
  successCallback: Function
) => {
  if (!(userIds.length && moduleIds.length)) {
    alert(translate(language, "PAGES.COHORT.USERS.MODALS.ADD_MODULE.ALERTS.ERROR.SELECT_USERS_MODULES"));
    return;
  }

  setLoading(true);
  ApiService.post(`cohorts/${cohortId}/users/modules/batch`, {
    user_ids: userIds,
    module_ids: moduleIds,
  })
    .then(() => {
      alert(translate(language, "PAGES.COHORT.USERS.MODALS.ADD_MODULE.ALERTS.SUCCESS"));
      successCallback?.();
    })
    .catch((error) => {
      console.error("Error adding modules", error);
      alert(translate(language, "PAGES.COHORT.USERS.MODALS.ADD_MODULE.ALERTS.ERROR.ADD_MODULES"));
    })
    .finally(() => {
      setLoading(false);
    });
};

export const fetchModules = (setModules: Function, setLoadingModules: Function, language: string) => {
  setLoadingModules(true);
  ApiService.get("modules?get_deprecated=false")
    .then((resp) => {
      setModules(resp?.data?.return?.modules || []);
    })
    .catch((error) => {
      console.error("Error to fetch modules", error);
      alert(translate(language, "PAGES.COHORT.USERS.ERROR.FETCH_MODULE"));
    })
    .finally(() => setLoadingModules(false));
};
