import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import DstButton from "../../../../../components/DstButton/DstButton";
import MultiSelectDropdown from "../../../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Popup from "../../../../../components/Popup/Popup";
import { Evaluation } from "../../../../../models/Evaluation";
import translate from "../../../../../services/Translate";
import { CohortUserEvaluationAttemptsPopupProps } from "./CohortUsersEvaluationAttemptPopup.d";
import { fetchEvaluations, inviteSelectedUsersToEvaluation } from "./CohortUsersEvaluationAttemptPopup.function";

export const CohortUserEvaluationAttemptsPopup = ({
  cohortId,
  language,
  openPopup,
  setOpenPopup,
  userIds,
}: CohortUserEvaluationAttemptsPopupProps) => {
  const [sendingInvitation, setSendingInvitation] = useState(false);
  const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
  const [scheduledDate, setScheduledDate] = useState<string>("");
  const [forceInvite, setForceInvite] = useState(false);
  const [selectedEvaluations, setSelectedEvaluations] = useState<Evaluation[]>([]);

  useEffect(() => {
    if (openPopup) {
      fetchEvaluations(setEvaluations);
    }
  }, [openPopup]);

  return (
    <Popup
      show={openPopup}
      onClose={() => setOpenPopup(false)}
      modalTitle={translate(language, "PAGES.COHORT.USERS.MODALS.EVALUATION_INVITE.TITLE")}
    >
      <Modal.Body>
        <MultiSelectDropdown
          mock={evaluations.length === 0}
          badgeItemProperty="description"
          displayItem={(evaluation: any) => (
            <span>
              {evaluation.description} <span className="dark-gray-font">({evaluation.s3_name})</span>
            </span>
          )}
          label={
            selectedEvaluations?.length
              ? selectedEvaluations[0].name
              : translate(language, "PAGES.COHORT.USERS.MODALS.EVALUATION_INVITE.SELECT_EVAL_LABEL")
          }
          toggleClass="text-truncate w-100 m-regular"
          onConfirm={setSelectedEvaluations}
          items={evaluations}
          language={language}
          isSingleSelect
          borderToggle
        />
        <div className="mt-3 m-regular">
          <label htmlFor="scheduledDate">
            {translate(language, "PAGES.COHORT.USERS.MODALS.EVALUATION_INVITE.SCHEDULE_DATE_LABEL")}
          </label>
          <input
            type="datetime-local"
            id="scheduledDate"
            className="form-control"
            value={scheduledDate}
            onChange={(e) => setScheduledDate(e.target.value)}
            min={new Date().toISOString().slice(0, 16)}
          />
        </div>

        <div className="mt-3 m-regular">
          <label>
            <input
              type="checkbox"
              checked={forceInvite}
              onChange={() => setForceInvite((forceInvite) => !forceInvite)}
            />
            <span className="ms-2">
              {translate(language, "PAGES.COHORT.USERS.MODALS.EVALUATION_INVITE.FORCE_INVITE")}
            </span>
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <DstButton
          variant="primary"
          value={translate(language, "PAGES.COHORT.USERS.MODALS.EVALUATION_INVITE.CONFIRM_BUTTON")}
          clickFunction={() => {
            setSendingInvitation(true);
            inviteSelectedUsersToEvaluation(
              +cohortId,
              selectedEvaluations[0].id,
              userIds,
              language,
              scheduledDate || undefined,
              forceInvite,
              () => setSendingInvitation(false),
              () => {
                setOpenPopup(false);
                setSelectedEvaluations([]);
              }
            );
          }}
          disabled={!selectedEvaluations.length || sendingInvitation}
          loading={sendingInvitation}
        />
      </Modal.Footer>
    </Popup>
  );
};

export default CohortUserEvaluationAttemptsPopup;
