import { useEffect, useState } from "react";
import React from "react";
import { Card, Col, Dropdown, DropdownButton, Placeholder, Row, Spinner } from "react-bootstrap";
import { Calendar, DateObject } from "react-multi-date-picker";

import DstButton from "../../../../components/DstButton/DstButton";
import DstGraph from "../../../../components/DstGraph/DstGraph";
import DstStatGraph from "../../../../components/DstStatGraph/DstStatGraph";
import Popup from "../../../../components/Popup/Popup";
import Selector from "../../../../components/Selector/Selector";
import { formatMinutesHourly, getDays, getMonths } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import {
  fetchUserCohorts,
  fetchUserLogTime,
  fetchUserLogTimeCsv,
  fetchUserLogs,
  handleDateChange,
} from "./UserActivity.function";
import "./UserActivity.scss";

const UserActivity = ({
  language,
  mock,
  userName,
  userModules,
  userId,
}: {
  language: string;
  mock?: boolean;
  userName: string;
  userModules: any;
  userId: number;
}) => {
  const [dates, setDates] = useState<DateObject[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openFullLogsPopup, setOpenFullLogsPopup] = useState(false);
  const [fullLogsLoading, setFullLogsLoading] = useState(true);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [userLogs, setUserLogs] = useState([]);
  const [userFullLogs, setUserFullLogs] = useState([]);
  const [userLogTime, setUserLogTime] = useState([]);
  const [legendTitle, setLegendTitle] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);
  const [barConfig, setBarConfig] = useState([]);
  const [mockLogtime, setMockLogtime] = useState(true);
  const [mockCohorts, setMockCohorts] = useState(true);
  const [mockLogs, setMockLogs] = useState(true);
  const [userCohorts, setUserCohorts] = useState([]);
  const [userCohortsId, setUserCohortsId] = useState<{ name: string; id: number }[]>([]);
  const [selectedCohort, setSelectedCohort] = useState(null);
  const [selectedCohortId, setSelectedCohortId] = useState(Number);
  const dataUserModules = userModules
    ? userModules.map((module: any) => ({
        label: module.module.name,
        value: module.module.duration,
      }))
    : [];
  const [showAll, setShowAll] = useState(false);

  const handleCohortSelection = (selectedItem: any) => {
    const matchingCohort = userCohortsId.find((cohort) => cohort.name === selectedItem.name);

    if (matchingCohort) {
      setSelectedCohort(selectedItem);
      setSelectedCohortId(matchingCohort.id);
    } else {
      console.error("No matching cohort found for selected item:", selectedItem.name);
    }
  };

  useEffect(() => {
    if (mock) return;
    fetchUserLogs(userId as number, setUserLogs, setMockLogs, language, 5);
    fetchUserLogTime(userId as number, setUserLogTime, setMockLogtime);
    fetchUserCohorts(userId as number, setUserCohorts, setUserCohortsId, setMockCohorts);
    if (dates && dates.length > 0) {
      handleDateChange(
        dates,
        language,
        userName,
        selectedCohortId,
        selectedCohort,
        setBarConfig,
        userLogTime,
        setGraphData,
        setIsDateRangeSelected,
        setIsLoading,
        setLegendTitle
      );
    }
    // eslint-disable-next-line
  }, [userId, language]);

  if (mock || mockCohorts || mockLogtime || mockLogs)
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center m-3">
          <div className="d-flex">
            <div style={{ width: 200, height: 30 }}>
              <Placeholder animation="glow">
                <Placeholder xs={12} style={{ height: 30 }} />
              </Placeholder>
            </div>
            <div className="ms-3" style={{ width: 150, height: 30 }}>
              <Placeholder animation="glow">
                <Placeholder xs={12} style={{ height: 30 }} />
              </Placeholder>
            </div>
          </div>
          <div style={{ width: 150, height: 40 }}>
            <Placeholder animation="glow">
              <Placeholder xs={12} style={{ height: 30 }} />
            </Placeholder>
          </div>
        </div>
        <div className="d-flex flex-column align-items-start m-3">
          <div className="d-flex w-100">
            <div className="w-75" style={{ height: 300 }}>
              <Placeholder animation="glow">
                <Placeholder xs={12} style={{ height: 300 }} />
              </Placeholder>
            </div>
            <div className="ms-3 w-25" style={{ height: 300 }}>
              <Placeholder animation="glow">
                <Placeholder xs={12} style={{ height: 300 }} />
              </Placeholder>
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 mt-3">
            <div className="w-50 me-2" style={{ height: 200 }}>
              <Placeholder animation="glow">
                <Placeholder xs={12} style={{ height: 200 }} />
              </Placeholder>
            </div>
            <div className="w-50" style={{ height: 200 }}>
              <Placeholder animation="glow">
                <Placeholder xs={12} style={{ height: 200 }} />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    );

  const adaptedHandleDateChange = (dateObjects: DateObject[]) => {
    setDates(dateObjects);
    handleDateChange(
      dateObjects,
      language,
      userName,
      selectedCohortId,
      selectedCohort,
      setBarConfig,
      userLogTime,
      setGraphData,
      setIsDateRangeSelected,
      setIsLoading,
      setLegendTitle
    );
  };

  const logRenderer = (log: any, language: string) => {
    const logTypes: { [key: string]: { icon: string; textKey: string } } = {
      log_in: { icon: "dst-icon-log-in-01", textKey: "LOG_IN" },
      log_out: { icon: "dst-icon-log-out-01", textKey: "LOG_OUT" },
      notebook_opened: { icon: "dst-icon-book-open-01", textKey: "NOTEBOOK_OPENED" },
      evaluation_started: { icon: "dst-icon-edit-05", textKey: "EVALUATION_STARTED" },
      evaluation_finished: { icon: "dst-icon-file-06", textKey: "EVALUATION_FINISHED" },
      support_requested: { icon: "dst-icon-help-circle", textKey: "SUPPORT_REQUESTED" },
      generic: { icon: "dst-icon-asterisk-01", textKey: "GENERIC_EVENT" },
    };

    const { icon, textKey } = logTypes[log.event_type] || logTypes.generic;
    return (
      <div>
        <div className="d-flex align-items-center">
          <span className={`${icon} s-bold`} />
          <h3 className="mt-2 ms-1 m-bold">{translate(language, `COMPONENTS.USER_ACTIVITY.FEED.${textKey}`)}</h3>
        </div>
        <div className="log-list text-wrap">
          <p className="ms-2 s-regular">
            {translate(language, `COMPONENTS.USER_ACTIVITY.FEED.${textKey}_TEXT`)} {log.timestamp}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Row className="user-activity-container mx-0 flex-wrap-reverse">
      <Col lg={9}>
        <Row className="mt-4">
          <Col xl={8} className="d-flex align-items-center">
            <h2 className="w-fit me-4">{translate(language, "COMPONENTS.USER_ACTIVITY.TITLE_INSIGHT")}</h2>
            <Selector
              items={userCohorts}
              onItemSelected={handleCohortSelection}
              enableDropDown={false}
              language={language}
            />
          </Col>
          <Col xl={4} className="d-flex align-items-center justify-content-xl-end mt-2 mt-xl-0">
            <DropdownButton
              className="s-bold"
              title={
                isLoadingCsv ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  translate(language, "COMPONENTS.USER_ACTIVITY.DROPDOWN.TITLE")
                )
              }
              variant="primary"
            >
              <Dropdown.Item
                className="s-bold"
                onClick={() => fetchUserLogTimeCsv(userId, userName, setIsLoadingCsv, 6)}
              >
                <i className="me-1 dst-icon-download-01"></i>
                {translate(language, "COMPONENTS.USER_ACTIVITY.DROPDOWN.LAST_SIX_MONTHS")}
              </Dropdown.Item>
              <Dropdown.Item className="s-bold" onClick={() => fetchUserLogTimeCsv(userId!, userName, setIsLoadingCsv)}>
                <i className="me-1 dst-icon-download-01"></i>
                {translate(language, "COMPONENTS.USER_ACTIVITY.DROPDOWN.ALL_TIME")}
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
        <div className="d-flex mt-3 me-3">
          <div className="w-100">
            <h3 className="s-bold text-uppercase dark-gray-font">
              {translate(language, "COMPONENTS.USER_ACTIVITY.TITLE_AVERAGE")}
            </h3>
            <div className="mt-5">
              <DstStatGraph data={dataUserModules} />
            </div>
            <div className="mt-4">
              {dataUserModules.slice(0, showAll ? dataUserModules.length : 4).map((module: any, index: any) => (
                <div
                  className="d-flex justify-content-between m-regular"
                  key={index}
                  style={{ borderBottom: "1px solid", borderBottomColor: "var(--color-smoke-gray)" }}
                >
                  <p>{module.label}</p>
                  <p>{formatMinutesHourly(module.value)}</p>
                </div>
              ))}
              <div className="m-regular d-inline-block" onClick={() => setShowAll(!showAll)}>
                <p className="clickable see-more-button">
                  {showAll
                    ? translate(language, "COMPONENTS.USER_ACTIVITY.SHOW_LESS")
                    : translate(language, "COMPONENTS.USER_ACTIVITY.SHOW_MORE")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex m-3 mt-5">
          <Calendar
            range
            rangeHover
            value={dates}
            className="calendar-container"
            onChange={adaptedHandleDateChange}
            maxDate={new DateObject().add(0, "day")}
            weekStartDayIndex={language === "en-US" ? 0 : 1}
            weekDays={getDays(language)}
            months={getMonths(language)}
          />
          <div className="w-100 h-100 text-center">
            {isLoading ? (
              <Placeholder as={Card} animation="glow" className="ms-4 w-100" style={{ height: 340 }}>
                <Placeholder as={Card.Body} xs={12} />
              </Placeholder>
            ) : isDateRangeSelected ? (
              <DstGraph
                data={graphData}
                width="100%"
                height={300}
                barsToShow={7}
                barConfig={barConfig}
                legendAlign="right"
                legendDisabled={true}
                legendVerticalAlign="top"
                legendIconType="circle"
                legendIconSize={8}
                legendTitle={legendTitle}
                legendTitleAlign="center"
                buttonPosition="bottom"
                buttonDivClassName="d-flex justify-content-center"
                type="userlogtime"
                xAxisDataKey="day"
              />
            ) : (
              <h3 className="mt-3 m-regular">{translate(language, "COMPONENTS.USER_ACTIVITY.SELECT")}</h3>
            )}
          </div>
        </div>
      </Col>
      <Col lg={3}>
        <div className="ps-2 mt-4">
          <h2>{translate(language, "COMPONENTS.USER_ACTIVITY.FEED.TITLE")}</h2>
          {userLogs.length > 0 ? (
            <React.Fragment>
              {userLogs.map((log: { event_type: string }, index: number) => (
                <div key={`log-${index}`}>{logRenderer(log, language)}</div>
              ))}
              <DstButton
                clickFunction={() => {
                  setOpenFullLogsPopup(true);
                  fetchUserLogs(userId as number, setUserFullLogs, setFullLogsLoading, language, 10000);
                }}
                btnClass="p-0"
                color="var(--color-smoke-gray)"
                value={translate(language, "COMPONENTS.USER_ACTIVITY.SHOW_ALL_LOGS_POPUP")}
                variant="transparent"
              />
              <Popup
                show={openFullLogsPopup}
                onClose={() => setOpenFullLogsPopup(false)}
                modalTitle={translate(language, "COMPONENTS.USER_ACTIVITY.POPUP_TITLE")}
                scrollable
              >
                {!fullLogsLoading ? (
                  <div id="popup-logs-list">
                    {userFullLogs.map((log: { event_type: string }) => logRenderer(log, language))}
                  </div>
                ) : (
                  <Spinner className="d-block mx-auto mb-2" color="var(--color-black)" />
                )}
              </Popup>
            </React.Fragment>
          ) : (
            <div className="mt-3 text-muted">{translate(language, "COMPONENTS.USER_ACTIVITY.FEED.NO_FEED_FOUND")}</div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default UserActivity;
